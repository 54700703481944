export default class GlobalValues {

  static get gtag_link(){
     return 'https://www.googletagmanager.com/gtag/js?id=G-YV3QHW1QZR'
  }

  static get gtag_config(){
    return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-YV3QHW1QZR');
    gtag('config', 'UA-198891898-1');
    gtag('config', 'AW-381166228');
    `
  }

}
