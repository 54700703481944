import React from 'react'
import API from './API'
// import {
//   // GoogleReCaptchaProvider,
//   GoogleReCaptcha
// } from 'react-google-recaptcha-v3';

import {
  loadReCaptcha,
  ReCaptcha
} from 'react-recaptcha-v3'


const API_URL = "https://common-mailer.epic-comm.net/public/api/sendmail";
const API_KEY = '89w5aFV2zvHdHZpwT56Km8xNVHa3YrXHwCVuPnFUzqg2d7PkAeNQ6WL3pBun65SHASMXtMHc5zzUfyPaneSAM2u7x6LSKNgTBtmspJFSxXWSPpHF4VV6sP7zzcqQAbbv'

// const ctaSection = ({ elements }) => {
class ctaSection extends React.Component {

  componentDidMount() {
    loadReCaptcha('6Ld5kLsaAAAAACanf11kUT4fmUEZvmb1tsiTqbpX', this.loadReCaptchaCallback);

    // const script = document.createElement("script");
    // script.src = "https://use.typekit.net/foobar.js";
    // script.async = true;
    // document.body.appendChild(script);
  }

  loadReCaptchaCallback=(obj)=>{
    // console.log('loadReCaptchaCallback:',obj)
  }

  constructor(props) {
	   super(props);
     this.state = {
       name:'',
       email:'',
       tel:'',
       content:'',
       company:'',

       // name:'almorak',
       // email:'almorak@epic-comm.com',
       // tel:'12312313',
       // content:'12311232131234',

       hasDoCheck:false,

       name_invalidMsg:'',
       company_invalidMsg:'',
       email_invalidMsg:'',
       tel_invalidMsg:'',
       content_invalidMsg:'',

       inputCheckList:[
         {key:'name',invalidObj:'name_invalidMsg',emptyCheckPrefix:'稱謂'},
         {key:'company',invalidObj:'company_invalidMsg',emptyCheckPrefix:'公司名稱'},
         {key:'email',invalidObj:'email_invalidMsg',emptyCheckPrefix:'電郵'},
         {key:'tel',invalidObj:'tel_invalidMsg',emptyCheckPrefix:'電話'},
         {key:'content',invalidObj:'content_invalidMsg',emptyCheckPrefix:'查詢內容',minLength:10},
       ],


       check_tvp:false,
       check_bud:false,
       check_sme:false,
       check_citf:false,

       checkboxDataList:[
         // {id:'tvp',name:'tvp',value:'tvp',stateName:'check_tvp',
         //  labelContent:'科技券 資助計劃 (TVP)',
         // },
         {id:'bud',name:'bud',value:'bud',stateName:'check_bud',
         labelContent:'BUD專項基金(BUD)',
         },
         {id:'sme',name:'sme',value:'sme',stateName:'check_sme',
         labelContent:'建造業創新及科技基金 (CITF)',
         },
         {id:'citf',name:'citf',value:'citf',stateName:'check_citf',
         labelContent:'中小企融資擔保計劃 (SME Financing Guarantee Scheme)',
         },
       ],

       currentReCaptchaToken:'',
       ReCaptchaSuccess:false,


     }
     this.onChange = this.onChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
  }



  onChange=(e)=>{
    // console.log('onChange:',e)
    this.setState({[e.target.name]: e.target.value})
  }


  sendAdsConversion=()=>{
    // console.log(window.gtag)
    if(window && window.gtag){
      window.gtag("event", "conversion", { send_to: 'AW-381166228/vguvCJTUgYwCEJTF4LUB',event_callback:this.sendAdsConversion_callback })
    }
  }
  sendAdsConversion_callback=(res)=>{
    // console.log('sendAdsConversion_callback:',res)
  }


  handleSubmit=async(e)=>{
    // console.log('handleSubmit:',e)

    e.preventDefault();
    // console.log(this.state)

    let invalidObjs = {
      hasDoCheck:true,
    }
    let isAllVaild = true

    let checkList = this.state.inputCheckList;

    for (var i = 0; i < checkList.length; i++) {
      if( !this.state[checkList[i].key]){ // empty
        invalidObjs[ checkList[i].invalidObj] = checkList[i].emptyCheckPrefix+'不能空白'
        isAllVaild = false;
      }
      else if (checkList[i].minLength && (this.state[checkList[i].key].length < checkList[i].minLength)) {
        invalidObjs[ checkList[i].invalidObj] = '最少 '+checkList[i].minLength+' 個字'
        isAllVaild = false;
      }
      else {
        invalidObjs[ checkList[i].invalidObj] = ''
      }
    }


    this.setState(invalidObjs)
    if(isAllVaild == false){
      return
    }

    this.setState({
      proccessGetRecaptchaToken:true,

    },()=>{
      this.updateToken();
    })
  }


  callApiSendmail=async()=>{
    if(this.state.apiProccessing == true){
      return;
    }

    let data = JSON.stringify({
      api_key:API_KEY,
      name: this.state.name,
      company:this.state.company,
      email_to: this.state.email,
      contact_tel: this.state.tel,
      content: this.state.content,
      recaptcha_token:this.state.currentReCaptchaToken,

      check_tvp:this.state.check_tvp?'Y':'',
      check_bud:this.state.check_bud?'Y':'',
      check_sme:this.state.check_sme?'Y':'',
      check_citf:this.state.check_citf?'Y':'',
    })


    this.setState({
      apiProccessing:true,
    },async()=>{
      let res = await API.postData(API_URL,data)
      // console.log('res:',res)
      this.setState({
        apiProccessing:false
      },async()=>{
        if(res.status && res.status == 'Y'){
          this.sendAdsConversion()
          alert(res.msg)
          window.location.reload();
        }else if(res.error) {
          alert(res.error)
        }else {
          alert('無法提交，請稍後再試')
          window.location.reload();
        }
      })
    })



  }


  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    // console.log("your recaptcha token => ",recaptchaToken)
    this.state.currentReCaptchaToken = recaptchaToken;
    if(this.state.proccessGetRecaptchaToken == true){
      this.state.proccessGetRecaptchaToken = false
      this.callApiSendmail()

    }
  }

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  renderCheckBox=({id,name,value,stateName,labelContent})=>{
    return(
      <div style={Styles.rowContainer} key={id}>
        <div style={Styles.inputCheckBoxDiv}>
        <input type="checkbox"
         id={id} name={name} value={value}
         checked={this.state[stateName]?true:false}
         onChange={()=>{
           let newObj = {}
           newObj[stateName] = !this.state[stateName]
           this.setState(newObj)
         }}
        style={Styles.inputCheckBox}/>
        </div>
        {
          <label style={Styles.checkboxLabel}
          onClick={()=>{
            let newObj = {}
            newObj[stateName] = !this.state[stateName]
            this.setState(newObj)
          }}
          >{labelContent}</label>
        }
      </div>
    )
  }

  render() {
    console.log(this.state.checkboxDataList)
    let checkBoxObjs = this.state.checkboxDataList.map((data,index)=>{
      return this.renderCheckBox(data);
    })

    return (
      <section id="four" className="main style2 special">
        <div className="grid-wrapper">
          <div className="col-6">
            <header className="major">
              <h2>聯絡我們</h2>
            </header>
            <div>旺角彌敦道610號</div>
            <div>荷李活商業中心18樓 1805-06室</div>
            <div><a href="enquiry@sealcave.com">enquiry@sealcave.com</a></div>
            <div>26481686 | 69944469</div>
            <br/><br/>
          </div>

          <div className="col-6" style={Styles.container} >

            <form
            // onSubmit={HandleSubmit}
            onSubmit={this.handleSubmit}
            >

              <label style={Styles.title}>
                立即查詢
              </label>
              <input style={Styles.input} type="text" name="name" placeholder="稱謂"
              value={this.state.name} onChange={this.onChange} />
              <label style={Styles.invalidLabel}> {this.state.name_invalidMsg} </label>

              <input style={Styles.input} type="text" name="company" placeholder="公司名稱"
              value={this.state.company} onChange={this.onChange} />
              <label style={Styles.invalidLabel}> {this.state.company_invalidMsg} </label>


              <input style={Styles.input} type="email" name="email" placeholder="電郵"
              value={this.state.email} onChange={this.onChange} />
              <label style={Styles.invalidLabel}>{this.state.email_invalidMsg}</label>


              <input style={Styles.input} type="text" name="tel" placeholder="電話"
              value={this.state.tel} onChange={this.onChange} />
              <label style={Styles.invalidLabel}>{this.state.tel_invalidMsg}</label>


              <textarea style={Styles.inputArea}  name="content" placeholder="查詢內容"
              value={this.state.content} onChange={this.onChange} />
              <label style={Styles.invalidLabel}>{this.state.content_invalidMsg}</label>


              <div style={Styles.columnContainer}>
              {
                // checkBoxObjs
              }
              </div>

              {
                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    // sitekey="<strong>6Ld5kLsaAAAAACanf11kUT4fmUEZvmb1tsiTqbpX</strong>"
                    sitekey="6Ld5kLsaAAAAACanf11kUT4fmUEZvmb1tsiTqbpX"
                    action='action_name'
                    verifyCallback={(obj)=>{this.verifyCallback(obj)}}
                />
              }


              <input style={Styles.submitBtn} type="submit"
              value={this.state.apiProccessing?"提交中" : "提交" }
              />

            </form>


          </div>
        </div>
      </section>
    )
  }
}


const Styles = {
  container:{
    // display: 'flex',
    // flexDirection: 'column',
    // border: '1px solid blue',
    paddingLeft:40,
    paddingRight:40,
  },

  columnContainer:{
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid blue',
  },
  rowContainer:{
    display: 'flex',
    flexDirection: 'row',
    // border: '1px solid red',
    // alignItems:'center',
    // justifyContent:'center',
    marginTop:10,
  },
  title:{
    color:'#ffffff',
    fontSize:24,
  },
  invalidLabel:{
    height:20,
    fontSize:14,
    color:'#ff0000',
    textAlign:'left',
    // marginBottom:12,
  },
  input:{
    color:'#ffffff',
    borderColor:'#ffffff',
    marginTop:10,
    height:36,
    placeholder:'#ffffff',
    backgroundColor:'#00000044',
    fontSize:14,
    // maxWidth:300,
  },
  validBorder:{
    borderColor:'#ffffff',
  },
  invalidBorder:{
    borderColor:'#ff0000',
  },
  inputArea:{
    color:'#ffffff',
    borderColor:'#ffffff',
    marginTop:10,
    height:72,
    placeholder:'#ffffff',
    backgroundColor:'#00000044',
    fontSize:14,
  },
  inputCheckBoxDiv:{
    display: 'flex',
    minWdith:16,
    // minHeight:16,
  },
  inputCheckBox:{
    margin:0,
    width:16,
    height:16,
    // borderRadius:4,
    // display:'block',
    // backgroundColor:'#00000044',
    // borderWidth:0,
  },
  checkboxLabel:{
    // margin:0,
     // minWdith:200,
    marginLeft:10,
    fontSize:14,
    color:'#ffffff',
    textAlign:'left',
    borderWidth:1,
    borderColor:'#ffffff',
    fontWeight:'400',
    lineHeight:1,
  },
  submitBtn:{
    marginTop:30,
    width:200,
  },

}
export default ctaSection
